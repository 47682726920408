import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DashboardService from '../../services/DashboardService';

interface IDashboard {
  data?: any,
  error: any,
  isLoading: boolean,
}

const initialState = {
  error: null,
  isLoading: false,
} as IDashboard;

export const getDataBars = createAsyncThunk<any, any, any>(
  'get/barCharts',
  async (userData, { rejectWithValue }) => {
    try {
     
      const response = await DashboardService.getDataBars();

      const data = response.data;
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.massage);
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getDataBars.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    }).addCase(getDataBars.fulfilled, (state, { payload }) => {
      console.log(payload);
      
      state.data = payload
      state.isLoading = false;
    }).addCase(getDataBars.rejected, (state, { payload }) => {
      if (payload) state.error = payload;
      state.isLoading = false;
    });
  }
});

export default dashboardSlice.reducer