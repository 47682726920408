import React, { useEffect } from 'react'
import './App.scss'

import {
  Dashboard,
  Map,
  Home,
  Login,
  Notifications,
  Contacts,
} from './pages'
import { Menu } from './components'

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { RequireAuth } from './components/RequireAuth';
import { useAppDispatch } from './hook';
import { fetchLogin } from './store/registration/userSlice';


function App() {
  const dispatch = useAppDispatch();





  useEffect(() => {
    dispatch(fetchLogin({}));
  })

  return (
    <Router>
      <Menu />
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/dashboard'} element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        } />
        <Route path={'/login'} element={<Login />} />
        <Route path={'/contacts'} element={<Contacts />} />
        <Route path={'/map'} element={<RequireAuth><Map /></RequireAuth>} />
        <Route path={'/notifications'} element={<RequireAuth><Notifications /></RequireAuth>} />
      </Routes>
    </Router>
  );
}

export default App;
