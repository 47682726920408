import React from 'react';

import './Home.scss';
import 'swiper/scss'

import river from '../../img/slides/river.png'
import after from '../../img/slides/after.png'
import before from '../../img/slides/before.png'

const Home = () => {
  return (
    <div className='home'>
      <div className="home__slide">
        <div className="home__slide__wrapper">
          <div className="home__slide__filter"></div>
          <img className='home__slide__background' src={river} alt="river" />
        </div>
        <h1 className='home__tetle'>Цифровой мониторинг фитоцинозов р.Средний Егорлык <br/>
          выполняется в рамках Договора № 310142 от «17» октября 2023 года. Непрерывный цифровой мониторинг реки Средний Егорлык позволяет контролировать и эффективно бороться с деградацией водных экосистем. Верификация результатов цифрового мониторинга производится по данным классических способ количественной и качественной оценки фитоценозов
        </h1>
      </div>
    </div>
  )
}

export default Home;
