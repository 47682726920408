import React from 'react'
import { IuiType } from '../../interfaces/IuiType';

import './Button.scss';

interface IButton {
  mod: IuiType;
  children: React.ReactNode | string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  className?: string;
}

export const Button = ({ children, mod, onClick, className }: IButton) => {
  return (
    <button onClick={onClick} className={`${className} button  button--${mod}`}>{children}</button>
  )
}
