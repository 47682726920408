import React from 'react'
import './Notifications.scss'

export const Notifications = () => {
  return (
    <div className='wrapper'>
      Страница для отображения информационных сообщений системы мониторинга
    </div>
  )
}

export default Notifications
