import { configureStore } from '@reduxjs/toolkit'
import userReducer from './registration/userSlice'
import dashboardSlice from './dashboard/dashboard.slice';

const store = configureStore({
  reducer: {
    user: userReducer,
    dashboard: dashboardSlice
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

