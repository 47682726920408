import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hook/hook';
import { fetchLogin, fetchLogout } from '../../store/registration/userSlice';
import { useNavigate } from 'react-router-dom';
import './Menu.scss'
import logo from '../../img/logo.png'
import { ReactComponent as DiversitySvg } from '../../img/nav/diversity.svg'


const Menu = () => {
  const isAuth = useAppSelector(state => state.user.isAuth);
  const dispatch = useAppDispatch();
  const menuEl = useRef<HTMLDivElement>(null);
  const [isActiveLogo, setIsActiveLogo] = useState<boolean>(false);
  let nav;

  const logoutFetch = async () => {
    await dispatch(fetchLogout());
  }

  const navHendler = (event: React.MouseEvent<HTMLElement>): void => {
    const target = event.target as Element;
    if (target.classList.contains('menu__img')) return;
    setIsActiveLogo(!isActiveLogo);
    menuEl.current?.classList.toggle('menu--active');
  }

  if (isAuth) {
    nav = (
      <>
        <Link className='menu__item-link' to={'/'}>
          <img className='logo' src={logo} alt="" />
        </Link>
        <ul className='menu__link-wrapper'>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/'}>
              <span className='menu__item-text' title='ГЛАВНАЯ'>ГЛАВНАЯ</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/map'}>
              <span className='menu__item-text' title='DASHBOARD'>КАРТА</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/dashboard'}>
              <span className='menu__item-text' title='DASHBOARD'>МОНИТОРИНГ</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/notifications'}>
              <span className='menu__item-text' title='УВЕДОМЛЕНИЯ'>СОБЫТИЯ</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/contacts'}>
              <span className='menu__item-text' title='КОНТАКТЫ'>КОНТАКТЫ</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' onClick={logoutFetch} to={'/'}>
              <span className='menu__item-text' title='ВЫЙТИ'>ВЫЙТИ</span>
            </Link>
          </li>
        </ul>
      </>
    )
  } else {
    nav = (
      <>
        <Link className='menu__item-link' to={'/'}>
          <img className='logo' src={logo} alt="" />
        </Link>
        <ul className='menu__link-wrapper'>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/'}>
              <span className='menu__item-text' title='ГЛАВНАЯ'>ГЛАВНАЯ</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/contacts'}>
              <span className='menu__item-text' title='КОНТАКТЫ'>КОНТАКТЫ</span>
            </Link>
          </li>
          <li className='menu__item'>
            <Link className='menu__item-link' to={'/login'}>
              <span className='menu__item-text' title='ВОЙТИ'>АВТОРИЗАЦИЯ</span>
            </Link>
          </li>
        </ul>
      </>
    )
  }

  return (
    <div className='menu' ref={menuEl} onClick={navHendler}>
      {nav}
    </div>
  )
}

export default Menu