export const chart = [
  {
    name: 'Температура',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: '2023',
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 6,
        ch0: 28,
        ch1: 23,
        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 5,
        ch0: 27,
        ch1: 20,
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 4,
        ch0: 26,
        ch1: 27,
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 6,
        ch0: 22,
        ch1: 26,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 7,
        ch0: 25,
        ch1: 24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 14,
        ch0: 18,
        ch1: 12,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 21,
        ch0: 15,
        ch1: 23,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 28,
        ch0: 12,
        ch1: 34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 23,
        ch0: 17,
        ch1: 15,
        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 16,
        ch0: 21,
        ch1: 12,
        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 10,
        ch0: 28,
        ch1: 23,
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 7,
        ch0: 23,
        ch1: null,
        month: "Дек"
      }
    ]
  },
  {
    name: 'Кислотность',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: '2023',
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 7,
        ch0: 28,
        ch1: 23,
        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 6,
        ch0: 27,
        ch1: 20,
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 8,
        ch0: 26,
        ch1: 27,
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 6,
        ch0: 22,
        ch1: 26,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 6,
        ch0: 25,
        ch1: 24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 8,
        ch0: 18,
        ch1: 12,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 9,
        ch0: 15,
        ch1: 23,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 8,
        ch0: 12,
        ch1: 34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 7,
        ch0: 17,
        ch1: 15,
        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 6,
        ch0: 21,
        ch1: 12,
        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 7,
        ch0: 28,
        ch1: 23,
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 8,
        ch0: 23,
        ch1: null,
        month: "Дек"
      }
    ]
  }, {
    name: 'Солесодержание',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: '2023',
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 550,
        ch0: 28,
        ch1: 23,
        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 500,
        ch0: 27,
        ch1: 20,
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 510,
        ch0: 26,
        ch1: 27,
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 520,
        ch0: 22,
        ch1: 26,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 390,
        ch0: 25,
        ch1: 24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 350,
        ch0: 18,
        ch1: 12,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 370,
        ch0: 15,
        ch1: 23,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 400,
        ch0: 12,
        ch1: 34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 440,
        ch0: 17,
        ch1: 15,
        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 455,
        ch0: 21,
        ch1: 12,
        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 450,
        ch0: 28,
        ch1: 23,
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 400,
        ch0: 23,
        ch1: null,
        month: "Дек"
      }
    ]
  },
  {
    name: 'Расторенный кислород',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: '2023',
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 1,
        ch0: 28,
        ch1: 23,
        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 2,
        ch0: 27,
        ch1: 20,
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 1,
        ch0: 26,
        ch1: 27,
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 2,
        ch0: 22,
        ch1: 26,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 3,
        ch0: 25,
        ch1: 24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 4,
        ch0: 18,
        ch1: 12,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 5,
        ch0: 15,
        ch1: 23,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 4,
        ch0: 12,
        ch1: 34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 5,
        ch0: 17,
        ch1: 15,
        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 6,
        ch0: 21,
        ch1: 12,
        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 5,
        ch0: 28,
        ch1: 23,
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 3,
        ch0: 23,
        ch1: null,
        month: "Дек"
      }
    ]
  },
  {
    name: 'Освещенность',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: '2023',
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 50,
        ch0: 28,
        ch1: 23,
        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 100,
        ch0: 27,
        ch1: 20,
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 100,
        ch0: 26,
        ch1: 27,
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 50,
        ch0: 22,
        ch1: 26,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 80,
        ch0: 25,
        ch1: 24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 200,
        ch0: 18,
        ch1: 12,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 290,
        ch0: 15,
        ch1: 23,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 300,
        ch0: 12,
        ch1: 34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 280,
        ch0: 17,
        ch1: 15,
        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 200,
        ch0: 21,
        ch1: 12,
        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 180,
        ch0: 28,
        ch1: 23,
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 90,
        ch0: 23,
        ch1: null,
        month: "Дек"
      }
    ]
  }
]  


export const fn = {
  name: 'Максимальный квантовый выход Fv/Fm',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: null,
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 0.16,

        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 0.11,
 
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 0.18,
  
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 0.17,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 0.24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 0.22,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 0.27,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 0.34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 0.28,

        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 0.24,

        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 0.26,
   
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 0.17,
        month: "Дек"
      }
    ]
} 
