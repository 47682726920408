export const fn = {
    name: 'Максимальный квантовый выход Fv/Fm',
    interval: 'Январь-Декабрь 2023',
    filter: {
      ch: null,
    },
    chart: [
      {
        _id: "652e7560832a59e0abe54a42",
        ch: 0.16,
        month: "Янв"
      },
      {
        _id: "652e756040d0a6e691f47997",
        ch: 0.11,
 
        month: "Фев"
      },
      {
        _id: "652e756011459b055ba5bdf4",
        ch: 0.18,
        month: "Мар"
      },
      {
        _id: "652e7560d306711e8d997775",
        ch: 0.17,
        month: "Апр"
      },
      {
        _id: "652e756048596708ad7fab9e",
        ch: 0.24,
        month: "Май"
      },
      {
        _id: "652e756010eda50ced2ea8df",
        ch: 0.22,
        month: "Июн"
      },
      {
        _id: "652e75604edd400893054dfa",
        ch: 0.27,
        month: "Июл"
      },
      {
        _id: "652e75604682f72bf18ded8e",
        ch: 0.34,
        month: "Авг",
      },
      {
        _id: "652e7560356dea66481d4a94",
        ch: 0.28,

        month: "Сен"
      },
      {
        _id: "652e7560987d67c668a27189",
        ch: 0.24,

        month: "Окт"
      },
      {
        _id: "652e75600366c550cefa21d8",
        ch: 0.26,
   
        month: "Нояб"
      },
      {
        _id: "652e75604ba90739320db8f7",
        ch: 0.17,
        month: "Дек"
      }
    ]
} 
