export const points = [
  // {
  //   positions: [
  //     [
  //       46.50936144762864,
  //       41.57073676586152
  //     ],
  //     [
  //       46.508933175580495,
  //       41.57072603702546
  //     ],
  //     [
  //       46.50891840751867,
  //       41.57289326190949
  //     ],
  //     [
  //       46.50936883159989,
  //       41.57288253307343
  //     ],
  //     [
  //       46.50935406365641,
  //       41.57074749469758
  //     ]
  //   ],
  //   color: '#01b7ff'
  // },

  // {
  //   positions: [
  //     [
  //       46.50887410330903,
  //       41.57243192195893
  //     ],
  //     [
  //       46.50863042951067,
  //       41.57243192195893
  //     ],
  //     [
  //       46.50864519765076,
  //       41.57295763492585
  //     ],
  //     [
  //       46.50888887138294,
  //       41.57294690608979
  //     ],
  //     [
  //       46.50888148734651,
  //       41.57243192195893
  //     ]
  //   ],
  //   color: '#af0000'
  // },
  {
    positions: [
      [
        46.508578740988746,
        41.57244265079499
      ],
      [
        46.50831291352823,
        41.57244265079499
      ],
      [
        46.50832768175457,
        41.57297909259797
      ],
      [
        46.50860089321845,
        41.57296836376191
      ],
      [
        46.50858612506632,
        41.57244265079499
      ]
    ],
    color: '#af0000'
  },
  // {
  //   positions: [
  //     [
  //       46.50913992802506,
  //       41.56997501850129
  //     ],
  //     [
  //       46.508866719270586,
  //       41.56997501850129
  //     ],
  //     [
  //       46.508866719270586,
  //       41.570586562156684
  //     ],
  //     [
  //       46.50916208002604,
  //       41.570586562156684
  //     ],
  //     [
  //       46.509154696026705,
  //       41.56998574733735
  //     ]
  //   ],
  //   color: '#d9ff01'
  // },
  // {
  //   positions: [
  //     [
  //       46.5088519511907,
  //       41.5707153081894
  //     ],
  //     [
  //       46.5085196683321,
  //       41.57070457935334
  //     ],
  //     [
  //       46.508512284245484,
  //       41.571047902107246
  //     ],
  //     [
  //       46.508866719270586,
  //       41.571058630943305
  //     ],
  //     [
  //       46.50888148734651,
  //       41.57072603702546
  //     ]
  //   ],
  //   color: '#d9ff01'
  // },
  // {
  //   positions: [
  //     [
  //       46.508866719270586,
  //       41.57114446163178
  //     ],
  //     [
  //       46.50852705241769,
  //       41.571155190467834
  //     ],
  //     [
  //       46.50854182058585,
  //       41.57144486904145
  //     ],
  //     [
  //       46.50885933523115,
  //       41.57145559787751
  //     ],
  //     [
  //       46.50885933523115,
  //       41.57114446163178
  //     ]
  //   ],
  //   color: '#d9ff01'
  // },
  // {
  //   positions: [
  //     [
  //       46.508822415018855,
  //       41.57000720500947
  //     ],
  //     [
  //       46.50860089321845,
  //       41.57000720500947
  //     ],
  //     [
  //       46.50859350914288,
  //       41.570597290992744
  //     ],
  //     [
  //       46.508807646926904,
  //       41.570597290992744
  //     ],
  //     [
  //       46.508807646926904,
  //       41.57002866268159
  //     ]
  //   ],
  //   color: '#d9ff01'
  // },

  // {
  //   positions: [
  //     [
  //       46.50931714378016,
  //       41.573097109794624
  //     ],
  //     [
  //       46.50834244997693,
  //       41.57317221164704
  //     ],
  //     [
  //       46.50833506586625,
  //       41.573848128318794
  //     ],
  //     [
  //       46.509339295708905,
  //       41.573826670646675
  //     ],
  //     [
  //       46.509339295708905,
  //       41.573086380958564
  //     ]
  //   ]
  //   ,
  //   color: '#d9ff01'
  // },
]