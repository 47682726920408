import React, { useEffect, useMemo, useRef, useState } from 'react'
import { chart, fn } from './mock';

import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import bloom from '../../img/1.jpg'
import pollution from '../../img/2.jpeg'
import fish from '../../img/3.jpg'
import reduction from '../../img/4.jpg'

import './Dashboard.scss'
import { DiagramConfig } from './config/diagram.config';
import { faker } from '@faker-js/faker';

import { useAppDispatch, useAppSelector } from '../../hook/hook';
import { Navigate, redirect, useLocation, useSearchParams } from 'react-router-dom';
import { LineChartLineCustom, SummaryData } from '../../components';
import { InfoWrapper } from '../../ui';
import { CahrtBar } from '../../components/BarChart/CahrtBar';
import { getDataBars } from '../../store/dashboard/dashboard.slice';
import { constants } from 'buffer';



export const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Line Chart - Multi Axis',
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

// export const ppmData = {
//   labels: temperature.map((item: any) => item.date),
//   datasets: [
//     {
//       label: 'Cолесодержание ppm',
//       data: temperature.map(() => faker.datatype.number({ min: 100, max: 10000 })),
//       borderColor: 'rgb(29,53,87)',
//       backgroundColor: 'rgba(241,250,238)',
//     },
//   ],
// };

// export const ehData = {
//   labels: temperature.map((item: any) => item.date),
//   datasets: [
//     {
//       label: 'Окислительно-восстановительный потенциал',
//       data: temperature.map(() => faker.datatype.number({ min: -300, max: 300 })),
//       borderColor: 'rgb(29,53,87)',
//       backgroundColor: 'rgba(241,250,238)',
//     },
//   ],
// };

const Dashboard = () => {
  var [searchParams] = useSearchParams();
  var storeId = searchParams.get('idPoint');
  const { data, isLoading } = useAppSelector((state) => state.dashboard);
  let location = useLocation();
  const dispatch = useAppDispatch();
  const [throttle, setThrottle] = useState<any>()
  // const [dataChart, setDataChart] = useState(second)

  useEffect(() => {
    if (!data) dispatch(getDataBars(1));
    const set = setInterval(() => {
      dispatch(getDataBars(1))
    }, 25000)
    return () => clearInterval(set);

  }, [data])

  const memoizeBars = useMemo(() => <CahrtBar data={data} />, [data])

  if (!storeId) {
    return <Navigate to="/map" state={{ from: location }} replace />;
  } else {
    return (
      <div className='Dashboard'>
        <div className='Dashboard__line'>
          {
            chart.map((item: any, index) => {
              if ((chart.length - 1) !== index) return <LineChartLineCustom data={item} />
            })
          }
        </div>
        <div className='Dashboard__line'>
          <SummaryData pointId={storeId} />
          {memoizeBars}
          <LineChartLineCustom data={chart[chart.length - 1] as any} />
        </div>
      </div>
    )
  }



}

export default Dashboard
