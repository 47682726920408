import React, { useState } from 'react'
import './Map.scss'
import { Circle, MapContainer, Marker, Pane, Polygon, Popup, TileLayer, useMapEvent, useMapEvents, } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { points } from './moc';
import { LatLngExpression } from 'leaflet';
import marker from '../../img/location.png';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet'

import L from 'leaflet';
import { Link, useSearchParams } from 'react-router-dom';

const iconPerson = new Icon({
  iconUrl: marker,
  iconSize: [35, 35],
});

function MyComponent() {
  const [cd, setCd] = useState<any>([])
  const map = useMapEvents({
    click: (event) => {
      // map.locate()
      console.log(cd);

      const i = cd.push([event.latlng.lat, event.latlng.lng])
      if (cd.lenght > 4) {
        console.log(JSON.stringify(cd))
      }


      // setCd(i)
      console.log('location found:', cd)
    },
    locationfound: (location) => {
      console.log('location found:', location)
    },
  })
  return null
}


export const Map = () => {


  const onDragStart = (event: any) => {
    console.log(event);

    // event.stopPropagation();
  };

  const onDragEnd = (event: any) => {
    console.log(event);
    // event.stopPropagation();
  };


  return (
    <div className='map'>
      <div className='map__container'>
        <MapContainer
          center={[46.50818738344216, 41.57378375530243]}
          zoom={17}
          scrollWheelZoom={false}
          zoomControl={false}
          dragging={false}

        // pointerEvents={false}
        >
          <MyComponent />
          <TileLayer
            attribution='&amp;copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />

          {
            points.map((point) => (<Polygon
              pathOptions={{ color: point.color }}
              positions={(point.positions as unknown as LatLngExpression[][])}
              eventHandlers={{
                mousedown: onDragStart,
                mouseup: onDragEnd
              }}
            />))
          }

          {/* <Circle center={[46.508724980150674, 41.571930348873146]} radius={5} pathOptions={{ color: '#ff01e1' }} />
          <Circle center={[46.508724980150674, 41.57211810350418]} radius={5} pathOptions={{ color: '#ff01e1' }} />
          <Circle center={[46.50859575897851, 41.57211810350418]} radius={5} pathOptions={{ color: '#ff01e1' }} />
          <Circle center={[46.50859206694051, 41.571930348873146]} radius={5} pathOptions={{ color: '#ff01e1' }} />
          <Circle center={[46.50871390406224, 41.571662127971656]} radius={5} pathOptions={{ color: '#ff01e1' }} />
          <Circle center={[46.50858468286375, 41.571678221225746]} radius={5} pathOptions={{ color: '#ff01e1' }} />

          <Circle center={[46.50833362365701, 41.571978628635414]} radius={2} pathOptions={{ color: '#f7ff01' }} />
          <Circle center={[46.50833362365701, 41.57207518815995]} radius={2} pathOptions={{ color: '#f7ff01' }} />

          <Circle center={[46.50963690368891, 41.571930348873146]} radius={5} pathOptions={{ color: '#8401ff' }} />
          <Circle center={[46.509647979589275, 41.57211273908615]} radius={5} pathOptions={{ color: '#8401ff' }} />

          <Circle center={[46.508261224704384, 41.572045683860786]} radius={2} pathOptions={{ color: '#af0000' }} /> */}






          <Marker position={[46.50861878757869, 41.5726207857132]} icon={iconPerson}>
            <Popup>
              Биопруд 1 <Link to={`/dashboard?idPoint=1`} className='home__link'>dashboard </Link>
            </Popup>
          </Marker>
        </MapContainer>
        <p>Для отображения данных выберите на карте точку мониторинга</p>

        {/* <MapContainer
          center={[46.525700767275616, 41.58926010131837]}
          zoom={13.5}
          scrollWheelZoom={false}
          zoomControl={false}
          dragging={false}

        // pointerEvents={false}
        >
          <MyComponent />
          <TileLayer
            attribution='&amp;copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={[46.50984878757869, 41.5721207857132]} icon={iconPerson}>
            <Popup>
              Точка мониторинга 1 <Link to={`/dashboard?idPoint=1`} className='home__link'>перейти к точке </Link>
            </Popup>
          </Marker>
          <Marker position={[46.52174837907474, 41.58625602722169]} icon={iconPerson}>
            <Popup>
              Точка мониторинга 2 <Link to={`/dashboard?idPoint=2`} className='home__link'>перейти к точке </Link>
            </Popup>
          </Marker>
          <Marker position={[46.53621573841221, 41.599302291870124]} icon={iconPerson}>
            <Popup>
              Точка мониторинга 3 <Link to={`/dashboard?idPoint=3`} className='home__link'>перейти к точке </Link>
            </Popup>
          </Marker>


          
        </MapContainer> */}


      </div>

    </div>

  )
}