import React, { useRef, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ReactComponent as Cross } from '../../img/cross.svg'

import './LineChartLineCustom.scss'
import { Button } from '../../elements';
import { IuiType } from '../../interfaces/IuiType';

//TODO Убрать any
interface ILineChartLineCustom {
  data: {
    name?: string,
    interval?: string,
    filter?: {
      ch0?: string,
      ch1?: string,
    },
    chart: {
      _id: string;
      ch0: number;
      month: string;
    }[];
  }

}

interface ITickAxis {
  x?: number,
  y?: number,
  stroke?: string,
  payload?: any,
}

export const theme = {
  tickText: '#A6A6A6',
  gridStroke: '#E5E5EF',
  lineStroke: '#364AFF',
  dotFill: '#1c0d00',
  dotStroke: '#b1b1b1',
}

export const LineChartLineCustom = ({ data }: ILineChartLineCustom) => {

  const [idInterval, setIdInterval] = useState<string | null>();
  const tooltip = useRef<HTMLDivElement>(null);

  const CustomTooltip = (props: any) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div className={`line-chart__tooltip ${props.payload[1] ? 'line-chart__tooltip-marker' : ''}`}>
          <p className="label">{`${props.payload[0].value}`}</p>
          {props.payload[1] ? <p className="label">{`${props.payload[1].value}`}</p> : null}
        </div>
      );
    }

    return null;
  };

  const CustomScatterDot: React.FC<{ cx?: number, cy?: number, them: string }> = ({ cx, cy, them }) => {
    return (
      <circle
        cx={cx}
        cy={cy}
        r={5}
        stroke={them}
        strokeWidth={2}
        fill={"#fff"} />
    );
  };

  const formatPrice = (price: number): string => {
    if (price > 1000) {
      return String(price).split('').slice(0, 1).join('') + 'k';
    }
    return String(price)
  }

  const CustomizedAxisTick: React.FC<ITickAxis> = ({ x, y, stroke, payload }) => {
    if (x && y) {
      y -= 12
    };
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fontSize={12}
          fill={theme.tickText}>{formatPrice(payload.value)}</text>
      </g>
    );
  }

  const changeInterval = (nameFild: string): void => {
    if (nameFild === idInterval) {
      setIdInterval(null)
      return;
    } else {
      setIdInterval(nameFild)
    }
  }
  const tooltipHandler = (nameFild?: string) => {
    if (nameFild) changeInterval(nameFild);
    tooltip.current?.classList.toggle('line-chart__tooltip--active');
  }

  return (
    <div className='line-chart'>
      <div className='line-chart__header'>{data.name}</div>
      <div className="line-chart__filter">
        <Button mod={IuiType.primary}><span className='line-chart__period'>
          <span className='item'></span> Август — Декабрь 2023</span>
        </Button>
        <Button
          onClick={() => tooltipHandler()}
          className='line-chart__custom-btn' mod={IuiType.primary}>
          <span className='line-chart__correlation'>Период <Cross /></span>
        </Button>
        <div className='line-chart__tooltip' ref={tooltip}>
          <p className='line-chart__tooltip-item' onClick={() => tooltipHandler('ch0')}>
            2022
          </p>
        </div>
      </div>
      <ResponsiveContainer width='100%' height={180}>
        <LineChart width={680} height={130} data={data.chart}
          margin={{ top: 0, right: 0, left: -30, bottom: 10 }}>
          <CartesianGrid stroke={theme.gridStroke} />
          <XAxis
            padding={{ left: 30, right: 30 }}
            dataKey="month"
            stroke='#fff'
            tick={<CustomizedAxisTick />}
            domain={['dataMax+500']}
            width={0}
          />
          <YAxis
            tick={<CustomizedAxisTick />}
            padding={{ top: 40 }}
            stroke='#fff'
          />
          <Tooltip
            content={CustomTooltip}
            cursor={{ stroke: '#A6A6A6', strokeWidth: 1 }}
          />
          <Line
            type="linear"
            dataKey="ch"
            stroke="#194EFF"
            fillOpacity={1}
            yAxisId={0}
            strokeWidth={2.5}
            activeDot={{ stroke: "#194EFF", strokeWidth: 1, fill: "#194EFF" }}
            dot={<CustomScatterDot them='#194EFF' />}
          />
          {idInterval ? (
            <Line
              type="linear"
              dataKey={idInterval}
              stroke="#B469FF"
              fillOpacity={1}
              yAxisId={0}
              strokeWidth={2.5}
              activeDot={{ stroke: "#B469FF", strokeWidth: 1, fill: "#B469FF" }}
              dot={<CustomScatterDot them='#B469FF' />}
            />
          ) : (
            null
          )}

        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
