import React from 'react'
import { InfoWrapper } from '../../ui'

import './SummaryData.scss'

interface ISummaryData {
  data?: {

  },
  pointId: string
}

const SummaryData = ({ data, pointId }: ISummaryData) => {
  const dataMoch = {
    temperature: 3,
    acidity: 8.2,
    salinity: '657 ppm',
    redox_potential: '+ 123mV',
    dissolved_oxygen: '4.1 мг/л',
    lighting: '117 Вт/м2',
  }
  return (
    <InfoWrapper>
      <div className='SummaryData'>
        Текущие показатели<br/>
        Точка мониторинга {pointId}
        <div className='SummaryData__wrpper'>
          <div className='SummaryData__line'>
            <span>Температура воды: </span>
            <span>Кислотность: </span>
            <span>Солесодержание: </span>
            <span>Окислительно-восстановительный потенциал: </span>
            <span>Растворенный кислород: </span>
            <span>Освещенность: </span>
          </div>
          <div className='SummaryData__line'>
            <span className='SummaryData__cell'>{dataMoch.temperature}C&deg;</span>
            <span className='SummaryData__cell'>{dataMoch.acidity}</span>
            <span className='SummaryData__cell'>{dataMoch.salinity}</span>
            <span className='SummaryData__cell'>{dataMoch.redox_potential}</span>
            <span className='SummaryData__cell'>{dataMoch.dissolved_oxygen}</span>
            <span className='SummaryData__cell'>{dataMoch.lighting}</span>
          </div>
        </div>
      </div>
    </InfoWrapper>
  )
}

export default SummaryData