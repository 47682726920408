import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { fn } from './mock';
import './ChartBar.scss';

export const CahrtBar = ({data}: any) => {

  console.log(data);
  


  return (
    <div>
      <div className='chart-bar'>
        <div className="chart-bar__header">
          <div className='chart-bar__title'>
            <p className='chart-bar__title-description'>{fn.name}</p>
          </div>
        </div>
        <ResponsiveContainer width='100%' height={290}>
          <BarChart
            height={300}
            data={data}
            margin={{ top: 0, right: 20, left: -20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis dataKey='date' />
            <YAxis/>
            <Bar
              barSize={8}
              radius={[24, 24, 24, 24]}
              dataKey='Fv_Fm'
              fill="#587fffc6"
              yAxisId={0}
            />
          </BarChart>
        </ResponsiveContainer>
        <div className='chart-bar__description'>
          <div className='chart-bar__wrapper'>
            <p className='chart-bar__indicators-month'>Показатели</p>
          </div>
        </div>
      </div>
    </div>
  )
}
