import React from 'react'
import './Contacts.scss'

export const Contacts = () => {
  return (
    <div className='contacts'>
      <div className='contacts__info'>
        <div className='contacts__wrapper'>
          <p>Директор: Мейерле Михаил Михайлович </p><br />
          <p>Адрес: г. Сальск, ул. Кирова, д. 130, ком. 2 </p><br />
          <p>Связь с водоканалом: </p><br />
          <ul>
            <li><a href="tel:88005517807">8(800)551-78-07</a> (Многоканальный телефон)</li>
            <li><a href="tel:88633101073">8(800)551-78-07</a> (Секретарь)</li>
            <li><a href="tel:88633101071">8(800)551-78-07</a> (Юридический отдел)</li>
            <li><a href="tel:88637251683">8(800)551-78-07</a> (Аварийная диспетчерская служба)</li>
          </ul>
        </div>
        <div className='contacts__bug'>
          <a href='mailto:corp@progress-aqua.com'>Сообщить о баге</a>
        </div>
      </div>
    </div>
  )
}
