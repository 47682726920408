import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../../hook";


export function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuth = useAppSelector(state => state.user.isAuth);
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
}