import React, { FC, ReactElement } from 'react'

import './InfoWrapper.scss';

interface InfoWrapperProps {
  children: ReactElement | string
}

export const InfoWrapper:FC<InfoWrapperProps> = ({ children }) => {
  return (
    <div className='InfoWrapper'>{children}</div>
  )
}