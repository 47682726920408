import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from '../interfaces/AuthResponse';

export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/login', {
      email,
      password,
    })
  }

  static async registration(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/registration', {
      email,
      password,
    })
  }

  static async logout(): Promise<AxiosResponse<AuthResponse>> {
    return $api.post('/set_acc/logout');
  }

  static async refreshSession(): Promise<AxiosResponse<AuthResponse>> {
    return $api.get('/set_acc/refresh');
  }
}